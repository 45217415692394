import React from 'react';
import './Header.css';

function Header() {
  return (
    <header className="app-header">
      <div className="logo">
        <a href="https://freeweb3.com">Web3 Legal Engineering</a>
      </div>
      <nav className="nav-menu">
        <ul>
          <li><a href="https://freeweb3.com" target="_blank" rel="noopener noreferrer">FreeWeb3</a></li>
          <li><a href="https://evidencer.io"target="_blank" rel="noopener noreferrer">Evidencer</a></li>
          <li><a href="https://nav.freeweb3.com" target="_blank" rel="noopener noreferrer">Navigator</a></li>
          <li><a href="https://demo.evidencer.io" target="_blank" rel="noopener noreferrer">Learn More</a></li>
          <li><a href="https://demos.evidencer.io" target="_blank" rel="noopener noreferrer">Schedule a Demo</a></li>
          </ul>
      </nav>
    </header>
  );
}

export default Header;