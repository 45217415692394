import React, { useState, useRef } from 'react';
import './VideoPlayer.css';
import ActionButtons from './ActionButtons';

function VideoPlayer() {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  
  const handleVideoClick = () => {
    if (!isPlaying) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <div className="video-container">
      <div className="video-wrapper" onClick={handleVideoClick}>
        {!isPlaying && (
          <div className="placeholder-overlay">
            <img 
              // src="https://freeweb3.infura-ipfs.io/ipfs/QmVaC94oitTdBzeq2sK7598xK328MwsPAahPaqoU84ZXqc" 
              src="https://freeweb3.infura-ipfs.io/ipfs/QmNpCM297Jo8eKmtWjmcbEXZPpVYJQ7W6ZutsgE1JnYBG3" 
              // src="https://freeweb3.infura-ipfs.io/ipfs/QmVzoDv7ZmShg43Y7u4s7fUJQFnXJkLwYYPWBP6AzoZDoa" 
              // src="https://freeweb3.infura-ipfs.io/ipfs/QmcddzmQY9cVmnhxMcCSX4HpcjhCCrFsZucYcZyLt8mT8a" 
              // src="https://freeweb3.infura-ipfs.io/ipfs/QmQCtyUHSVf8avzyRo6y3qL2JyqfdDZcLLukqWp46bDnj8" 
              // src="https://freeweb3.infura-ipfs.io/ipfs/QmWNkHZCZNT4w1avRe5XFxAs4WHx1DfVFdJukBVpbCzker"

              alt="Video placeholder" 
              className="placeholder-image"
            />
            <div className="play-icon">▶</div>
          </div>
        )}
        <video 
          ref={videoRef}
          controls={isPlaying}
          className="responsive-video"
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        >
          <source src="https://freeweb3.infura-ipfs.io/ipfs/QmcU3UtN6eBv6VbMohFF9T4k26ssqEpW6beswZoiMsHy3x" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <ActionButtons className="action-buttons" />

      <div className="video-wrapper" onClick={handleVideoClick}>
        {!isPlaying && (
          <div className="placeholder-overlay">
            <img 
              src="https://freeweb3.infura-ipfs.io/ipfs/QmQCtyUHSVf8avzyRo6y3qL2JyqfdDZcLLukqWp46bDnj8" 
              alt="Video placeholder" 
              className="placeholder-image"
            />
            <div className="play-icon">▶</div>
          </div>
        )}
        <video 
          ref={videoRef}
          controls={isPlaying}
          className="responsive-video"
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        >
          <source src="https://freeweb3.infura-ipfs.io/ipfs/QmRyawYDBnUxkJaWQhMAmRth3w2DTyFrZhUU2JUoohJxve" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>


      
      {/* <ActionButtons /> */}
    </div>
  );
}

export default VideoPlayer;







