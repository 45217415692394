import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="app-footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Web3 Legal Engineering</h3>
          <p><strong>Evidence-based protocols for decentralized applications.</strong> Bridging traditional legal frameworks with blockchain technology. </p>
          <p></p>
        </div>
        
        <div className="footer-section">
          <h3>Resources</h3>
          <div className="footer-links">
            <a href="https://evidencer.freeweb3.com/" target="_blank" rel="noopener noreferrer">Documentation</a>
            <a href="https://evidencer.io/" target="_blank" rel="noopener noreferrer">Implementation Guides</a>
            <a href="https://nav.freeweb3.com/" target="_blank" rel="noopener noreferrer">Learning Center</a>
            <a href="https://freeweb3.com/" target="_blank" rel="noopener noreferrer">Legal Resources</a>
          </div>
        </div>
        
        <div className="footer-section">
          <h3>Connect</h3>
          <div className="footer-links">
            {/* <a href="https://freeweb3.com/community" target="_blank" rel="noopener noreferrer">Community Forum</a> */}
            <a href="https://evidencer.io/" target="_blank" rel="noopener noreferrer">Contact</a>
            <a href="https://nav.freeweb3.com" target="_blank" rel="noopener noreferrer">Events</a>
            <a href="https://lex.freeweb3.com" target="_blank" rel="noopener noreferrer">Newsletter</a>
          </div>
        </div>
      </div>
      
      <div className="copyright">
        &copy; {new Date().getFullYear()} Web3 Legal Engineering. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;