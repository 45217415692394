import React from 'react';
import './ActionButtons.css';

function ActionButtons() {
  return (
    <div className="action-buttons">
      <a href="https://freeweb3.com" className="action-button" target="_blank" rel="noopener noreferrer">
        FreeWeb3
      </a>
      <a href="https://evidencer.io" className="action-button" target="_blank" rel="noopener noreferrer">
        Evidencer
      </a>
      <a href="https://nav.freeweb3.com" className="action-button" target="_blank" rel="noopener noreferrer">
        Navigation
      </a>
    </div>
  );
}

export default ActionButtons;